@import './theme/var';

html.is-in-frame {
  #root {
    height: 100%;
    overflow-y: auto;
  }
}

.ant-layout-header {
  height: 56px !important;
  line-height: 56px !important;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  &.disabled {
    color: @disabled-color;
    &:hover {
      color: @disabled-color;
    }
  }
}

.error,
.danger {
  color: @error-color;
}

.primary {
  color: @primary-color;
}

.success {
  color: @green-7;
}

.ant-btn.btn-success {
  color: #fff;
  background-color: @success-color;
  border-color: @success-color;

  &:focus,
  &:active,
  &:hover {
    color: #fff;
    background-color: lighten(@success-color, 4%);
    border-color: lighten(@success-color, 4%);
  }
}

.list-item {
  &__content {
    flex: auto;
    padding: 8px 0;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: @font-size-lg;
  }

  &__desc {
    color: @text-color-secondary;
  }

  &__actions {
    flex: 0 0 auto;
    margin-left: 16px;
  }
}

.row-disable-hover {
  &:hover > td {
    background: transparent !important;
  }
}

.select-box {
  max-height: 60vh;
  overflow-y: auto;

  .questions {
    .children {
      margin-left: 2em;
    }
  }

  .ant-row.question {
    .title {
      display: flex;
      align-items: center;
    }

    .content {
      margin-left: 1em;
      max-width: 15em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #999 !important;
      font-size: 13px !important;
    }
  }
}

.rich-text {
  max-width: 900px;
  margin: 0 auto;

  * {
    word-break: break-all;
  }

  .image-wrapper {
    margin-bottom: 16px;
    text-align: right;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      border-radius: @border-radius-sm;
    }
  }
}

.ant-modal.image-drawer-dialog {
  top: 0;
  width: 100vw !important;
  padding-bottom: 0;

  .ant-modal-body {
    height: calc(100vh - 55px);
    padding: 0;
    background-color: #eee;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .ant-modal-header {
    padding: 11px 24px;
  }

  .ant-modal-title {
    position: relative;
    .score-input-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ant-picker-now {
  display: none !important;
}

.content-images-bordered {
  img {
    display: block;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
}

.step-score-form {
  .item {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
    background-color: white;
    padding: 8px 16px;

    .bd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-input-number {
        flex: 0 0 60px;
        margin: 0 4px;
      }
    }
    .desc {
      margin-top: 4px;
      color: #999;
      font-size: 13px;
    }

    &.score {
      margin-top: 24px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .num {
        margin: 0 4px;
      }
    }
  }
}

@import 'theme/question';
@import '~viewerjs/dist/viewer.css';

