@import 'theme/var';

.question-preview-list {
  .group {
    margin-bottom: 24px;

    .title {
      padding: 8px 0;
      color: #000;
      font-size: @font-size-lg;

      .desc {
        margin-left: 16px;
        color: @text-color-secondary;
        font-size: @font-size-base;
      }
    }

    .question {
      margin-bottom: 8px;
      background-color: #f7f7f7;

      .content {
        .index {
          margin-right: 8px;
          color: @blue-6;
          font-size: @font-size-lg + 2;
        }

        .ct {
          color: #000;

          * {
            font-size: @font-size-base !important;
            text-indent: 0 !important;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .answers-right {
        margin-top: 8px;

        .answer {
          margin-bottom: 4px;

          &.right {
            color: @green-7;
          }
        }

        * {
          font-size: @font-size-base !important;
          text-indent: 0 !important;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .ant-card-body {
          background-color: #fff;
        }
      }
    }
  }
}

.question-marking-item {
  .header {
    margin-bottom: 16px;

    .title {
      color: @blue-7;
      font-weight: bold;

      &__group {
        margin-bottom: 8px;
      }
    }
  }

  .question-item {
    margin-bottom: 16px;

    &:last-child {
      border-bottom: none;
    }

    .answer-marking-toolbar {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #f4f4f4;
      padding: 12px 0;
      margin-bottom: 16px;
      position: relative;
      min-height: 24px * 2;

      > .ant-btn {
        margin: 0 4px;

        &:last-child {
          margin-right: 0;
        }
      }

      .text-word-count {
        position: absolute;
        left: 0;
        // top: 0;
        color: #999;
        font-size: 12px;
      }
    }

    .answer {
      .title {
        display: flex;
        align-items: center;
        .right {
          margin-left: auto;
        }
      }
      .ant-row:last-child .answer-marking-toolbar {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .content {
    margin-bottom: 16px;

    p {
      margin-bottom: 0.5em;
      font-size: @font-size-base !important;
    }
  }

  .answer {
    margin-bottom: 8px;
    padding: 12px;
    color: @blue-7;
    font-size: @font-size-base;
    background-color: #fff;
    border: 2px solid @primary-color;
    border-radius: @border-radius-base + 2;
    box-shadow: 0 0 8px rgba(@primary-color, 0.2);

    .title {
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  .nesting-children-group {
    .title {
      margin-bottom: 8px;
    }
    > div {
      padding: 8px;
      background-color: #f5f5f5;
    }
  }

  .answer-right {
    padding: 8px;
    color: @green-8;
    font-size: @font-size-base;
    border-radius: @border-radius-base;

    .title {
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  .rich-text-content {
    text-indent: 0 !important;

    * {
      font-size: @font-size-lg !important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
