@import 'src/theme/var';

.question-index-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0 0;
  overflow-x: auto;
  background-color: #fff;

  &.affixed {
    width: calc(100% + 24px * 2);
    margin-left: -24px;
    padding: 12px 24px;
    box-shadow: 0 4px 16px rgba(#000, 0.1);
  }

  .filter {
    a {
      color: @text-color;
      &.active {
        color: @primary-color;
      }
    }
  }
}

.question-index {
  &__group {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }
  &__group-name {
    flex: 0 0 auto;
    margin-right: 8px;
  }
  &__question-list {
    display: flex;
    align-items: center;
  }
  &__item {
    margin-right: 16px;
    padding: 0 0.8em;
    height: 22px;
    line-height: 23px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(#000, 0.2);
    background-color: @border-color-base;
    border-radius: 99px;
    cursor: pointer;

    &.disable {
      cursor: not-allowed;
    }
    &.touched {
      background-color: @blue-4;
    }
    &.active {
      background-color: @primary-color;
    }
  }
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.answer-inline-drawer-container {
  margin-bottom: 16px;
  padding: 8px;
  background-color: #fafafa;

  &:last-child {
    margin-bottom: 0;
  }

  .toolbar {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}

.answer-inline-drawer-extra-toolbar {
  display: flex;
  justify-content: flex-end;
}

