@import 'src/theme/var';

.marking-form {
  padding: 0 8px 8px;
  background-color: #fafafa;
  transition: none;
  max-height: calc(95vh - 56px);
  overflow-y: auto;

  .header {
    margin-bottom: 16px;
    .title {
      color: @blue-7;
      font-weight: bold;
      &__group {
        margin-bottom: 8px;
      }
    }
  }
  .score-input-form {
    margin-bottom: 16px;
    padding: 16px 8px;
    font-size: @font-size-lg;
    background-color: #fff;
    border-radius: @border-radius-base + 2;
    box-shadow: 0 0 8px rgba(#000, 0.04);
  }
  .score-input-action {
    .label {
      margin-bottom: 4px;
      font-weight: bold;
    }
  }

  .nesting-score-form.highlight {
    .score-input-form {
      animation: highlight 1s ease-in-out;
    }
  }
}

@keyframes highlight {
  0% {
    background-color: @blue-6;
  }
  100% {
    background-color: transparent;
  }
}

