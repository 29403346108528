.teacher-report-header {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    .tag {
      margin-left: 12px;
      font-size: 14px;
      padding: 2px 7px;
      + .tag {
        margin-left: 5px;
      }
    }
  }
}

